import React from 'react';
import { useQuery } from '@apollo/client';

import { VOUCHERS_FILTERABLE_LIST } from '../queries/voucher';
import PageTitle from '../components/layout/PageTitle';
import Preloader from '../components/Preloader';
import { Link, useParams } from 'react-router-dom';
import { PARTNER_LIST } from '../queries/partner';
import { LOCALITY_LIST } from '../queries/locality';
import VoucherCard from '../components/VoucherCard';

const VoucherList = () => {

    const params = useParams();
    const { loading, data } = useQuery(VOUCHERS_FILTERABLE_LIST, {variables: {
        partners: params.org === "-" ? undefined : params.org,
        localities: params.loc
    }});
    const { loading : loading_partners, data: partners } = useQuery(PARTNER_LIST);
    const { loading: loading_localities, data: localities } = useQuery(LOCALITY_LIST);
    
    return (
        <>
            {(loading || loading_partners || loading_localities) && <Preloader />}

            <PageTitle title={"DOBROvouchery"} crumbs={[{title: "DOBROvouchery", url: "/dobrovouchery"}]}/>
            
            <section className="shop-pg-section section-padding">
            <div className="container">
                <div className="row">
                <div className="col col-md-4 col-md-push-8">
                        <div className="blog-sidebar">
                            <div className="widget category-widget">
                                <h3>Organizace</h3>
                                <ul>
                                    {partners && partners.partners.map((part) => <li key={part.id}><Link to={"/dobrovouchery/"+part.url}>{part.name} </Link></li>)}
                                </ul>
                            </div>
                            <div className="widget tag-widget">
                                <h3>Lokalita</h3>
                                <ul>
                                    {localities && localities.localities.map((loc) => <li key={loc.id}><Link to={"/dobrovouchery/-/"+loc.url}>{loc.name} </Link></li>)}
                                </ul>
                            </div>                    
                        </div>
                    </div>                    
                    <div className="col col-md-8 col-md-pull-4">
                        {data && data.vouchers && data.vouchers.length > 0 && 
                        <div className="shop-grids">
                            {data.vouchers.map((vouch) => <VoucherCard vouch={vouch} key={vouch.id} />)}
                        </div>} 
                    </div>

                </div>
            </div>
        </section>
        </>
    );
}

export default VoucherList;
