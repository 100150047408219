import { gql } from '@apollo/client';

const VOUCHER_FIELDS = gql`
  fragment VoucherFields on Voucher {
    id
    name
    label
    description
    url
    price
    price_distribution { 
        target
        percentage
        value
    }
    gallery {
      id
      formats
    }
    partner {
      id
      name
    }
    locality {
      id
      name
    }
    parameter {
      param
      value
    }
    availability
    articles {
      id
      name
      label
      url
      photo {
        formats
      }
    }
  }
`;

const VOUCHER_LIST_FIELDS = gql`
  fragment VoucherListFields on Voucher {
    id
    name
    label
    description
    url
    price
    gallery {
      id
      formats
    }
    partner {
      id
      name
    }
    availability
  }
`;

const VOUCHER_DETAIL = gql`
  ${VOUCHER_FIELDS}
  query getVoucher($url: String) {
    vouchers(where: {url: $url}) {
      ...VoucherFields
    }
  }
`;

const VOUCHERS_FILTERABLE_LIST = gql`
  ${VOUCHER_LIST_FIELDS}
  query getVoucherList($partners : [String], $localities: [String], $ishp: Boolean, $istop: Boolean) {
    vouchers(where: {is_top: $istop, is_hp: $ishp, partner: { url: $partners}, locality: { url: $localities}}) {
      ...VoucherListFields
    }
  }
`;


export {VOUCHER_FIELDS, VOUCHER_LIST_FIELDS, VOUCHER_DETAIL, VOUCHERS_FILTERABLE_LIST}