import React from 'react';
import StoriesCarouselBlock from '../blocks/StoriesCarouselBlock';
import PageTitle from '../components/layout/PageTitle';

const StoryList = () => {

    return (
        <>
        <PageTitle title={"DOBROpříběhy"} crumbs={[{title: "DOBROpříběhy", url: "/pribehy"}]}/>

        <section className="blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-10 col-md-offset-1">
                        <div className="blog-content">
                            <div className="post">
                                <blockquote><p>
                                Naděje není přesvědčení, že něco dobře dopadne, ale jistota, že má něco smysl - bez ohledu na to, jak to dopadne.<br /><i>Václav Havel</i>
                                </p></blockquote>
                                <p>
                                Nečekejte instantní návody a příběhy se šťastným koncem navždy. Naše příběhy jsou o dobrém začátku a lidech, kteří s jistotou a přesvědčením nabízí smysluplnou inspiraci a naději... Dělají to co umí nejlépe a nejraději v harmonii sami se sebou, ostatními a okolím. DOBRO nejde jinak než dělat osobně!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="causes-section causes-section-s2 section-padding">
            <div className="container-fluid">
                <StoriesCarouselBlock />
            </div>
        </section>
        </>
    );
}

export default StoryList;
