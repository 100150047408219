import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import showdown from 'showdown';

import { VOUCHER_DETAIL } from '../queries/voucher';
import PageTitle from '../components/layout/PageTitle';
import Preloader from '../components/Preloader';
import { getAPIurl } from '../utils/environment';

import { useForm } from "react-hook-form";
import { cartAdd } from '../redux/actions/cart';
import {useDispatch} from 'react-redux';
import ParameterName from '../components/ParameterName';
import StoriesCarousel from '../components/StoriesCarousel';

const converter = new showdown.Converter()

const VoucherDetail = () => {

  let { url } = useParams();
  let dispatch = useDispatch()

  const { loading, data } = useQuery(VOUCHER_DETAIL, {variables: { url }});
  let voucher = data && data.vouchers && data.vouchers[0]

  const onSubmit = data => dispatch(cartAdd(voucher.id, data.productCount));

  const [panelActive, setPanelActive] = useState(1);

  const { register, handleSubmit } = useForm({
    defaultValues: {
        productCount: 1
    }
  });

  const setPanelHandler = (e, panel) => {
    e.preventDefault();
    e.stopPropagation();
    setPanelActive(panel)
    };

  return (
    <>
        {loading && <Preloader />}

        <PageTitle title={"DOBROvouchery"} crumbs={[{title: "DOBROvouchery", url: "/dobrovouchery"}]}/>
      
        {voucher &&
        <section className="shop-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-6">
                        <div className="shop-single-slider">
                            <div className="slider-for">
                              {voucher.gallery.map((img) => <div key={img.id}><img src={getAPIurl()+img.formats.medium.url} alt={img.alternativeText}/></div>)}
                            </div>
                        </div>
                    </div>

                    <div className="col col-md-6">
                        <div className="product-details">
                            <h2>{voucher.name}</h2>
                            <div className="price">
                                <span className="current">{voucher.price} Kč</span>
                            </div>
                            <p>{voucher.label}</p>

                            <div className="product-option">
                                {voucher.availability > 0 ? 
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="product-row">
                                        <div>
                                            <input id="productCount" type="text" name="productCount" className="form-control" ref={register}/>
                                        </div>
                                        
                                        <div>
                                            <button type="submit" className="theme-btn">Do košíku</button>
                                        </div>

                                    </div>
                                </form>
                                :
                                <div className="alert alert-danger">Vyprodáno!</div>    
                                }
                        </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-xs-12">
                        <div className="product-info">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className={panelActive === 1 ? "active" : ""}><a href="#01" onClick={(e) => setPanelHandler(e, 1)}>Popis</a></li>
                                <li className={panelActive === 2 ? "active" : ""}><a href="#02" onClick={(e) => setPanelHandler(e, 2)}>Parametry</a></li>
                                <li className={panelActive === 2 ? "active" : ""}><a href="#03" onClick={(e) => setPanelHandler(e, 3)}>Poskytovatel</a></li>
                            </ul>

                            <div className="tab-content">
                                <div role="tabpanel" className={panelActive === 1 ? "tab-pane fade in active":"tab-pane"}>
                                    <p dangerouslySetInnerHTML={{__html: converter.makeHtml(voucher.description)}}></p>
                                </div>
                                <div role="tabpanel" className={panelActive === 2 ? "tab-pane fade in active":"tab-pane"}>
                                    {voucher.parameter && voucher.parameter.map((par) => <p key={par.param}><strong><ParameterName type={par.param} />:</strong> {par.value}</p>)}
                                </div>                   
                                <div role="tabpanel" className={panelActive === 3 ? "tab-pane fade in active":"tab-pane"}>
                                    <h3>{voucher.partner.name}</h3>
                                </div>                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>}  

        {voucher && voucher.articles && voucher.articles.length > 0 &&
        <section className="causes-section causes-section-s2 section-padding">
            <div className="container-fluid">
                <div class="section-title-s2">
                    <span>#DOBROPŘÍBĚHY</span>
                </div>
                <div className="content-area causes-slider">
                    <StoriesCarousel data={voucher} />
                </div>
            </div>
        </section>
        }
    </>
  );
}

export default VoucherDetail;
