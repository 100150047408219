import React from 'react';

import PageTitle from '../components/layout/PageTitle';
import PartnerToptax from '../assets/images/partners/toptax-1.png';

const Partners = () => {

  return (
    <>

        <PageTitle title={"DOBROpartneři"} crumbs={[]}/>
      
        <section className="blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-10 col-md-offset-1">
                        <div className="blog-content">
                            <div className="post">
                                <blockquote><p>
                                Člověk by se měl chovat tak, jak si myslí, že by se měli chovat všichni.<br /><i>Václav Havel</i>
                                </p></blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>      
        <section class="testimoninals-funfact-section testimonials-pg-section ">
            <div class="container">
                <div class="row">
                    <div class="col col-xs-12 col-sm-6">
                        <div class="testimonials-slider-s2 testimonials-slider-area">
                            <div class="grid">
                                <div class="author">
                                    <div class="author-img">
                                        <img src={PartnerToptax} alt=""/>
                                    </div>
                                    <h5>TOPTAX innovations a.s.</h5>
                                    <span>Účetní a daňová kancelář</span>
                                </div>
                                <p>Peníze řadíme je stejně jako DOBRO až na první místo</p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    </>
  );
}

export default Partners;
