import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo-dobrota.png';
import CgLogo from '../../assets/images/comgate logo.png';
import VisaLogo from '../../assets/images/MainVisaBlack.png';
import MastercardLogo from '../../assets/images/mastercard.png';

const Footer = () => {

  return (
    <footer className="site-footer">
    <div className="upper-footer">
        <div className="container">
            <div className="row">
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget about-widget">
                        <div className="widget-title">
                            <h3>
                                <span className="hidden">About us</span>
                                <img src={Logo} alt="" />
                            </h3>
                        </div>
                        <p>Jsme agentura zaměřená na CSR a fundraising. Vytváříme back office pro DOBROsrdečné projekty.</p>
                        <div className="social-icons">
                            <ul>
                                <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                            </ul>
                        </div>
                        <div style={{marginTop:18}}>
                            <a href="https://www.comgate.cz/cz/platebni-brana"><img src={CgLogo} height="28" alt="Platební brána ComGate" /></a>
                            <img src={VisaLogo} height="50" alt="" style={{marginLeft: 8}} />
                            <img src={MastercardLogo} height="50" alt="" />
                        </div>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget contact-widget service-link-widget">
                        <div className="widget-title">
                            <h3>Adresa</h3>
                        </div>
                        <ul>
                            <li>DOBRO&amp;TA s.r.o.</li>
                            <li>U Břízek 115/8, 747 94 Dobroslavice</li>
                            <li><span>Tel.:</span> +420 736 614 438</li>
                            <li><span>Email:</span> office@dobro-ta.cz</li>
                            <li>IČO: 01966910</li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Rychlé odkazy</h3>
                        </div>
                        <ul>
                            <li><Link to="/clanek/dobroandta">O nás</Link></li>
                            <li><a href="https://www.kridleni.cz">Křídlení</a></li>
                            <li><Link to="/clanek/dobrovolnictvi">Dobrovolnictví</Link></li>
                            <li><Link to="/clanek/jak-funguje">Jak funguje</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget newsletter-widget">
                        <div className="widget-title">
                            <h3>Novinky</h3>
                        </div>
                        <p>Pokud chcete být informování o novinkách, vyplňte Vaši emailovou adresu</p>
                        <form>
                            <div className="input-1">
                                <input type="email" className="form-control" placeholder="Emailová adresa *" required />
                            </div>
                            <div className="submit clearfix">
                                <button type="submit"><i className="fi flaticon-paper-plane"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="lower-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-xs-12">
                    <p className="copyright">Copyright &copy; 2021 DOBRO&amp;TA. Všechna práva vyhrazena</p>
                    <div className="extra-link">
                        <ul>
                            <li><Link to="/clanek/zasady-ochrany-osobnich-udaju">Zpracování osobních údajů</Link></li>
                            <li><Link to="/clanek/obchodni-podminky">Obchodní podmínky</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

  );
}

export default Footer;
