import React from 'react';
import { Link } from 'react-router-dom';

import HomepageCarousel from '../blocks/HomepageCarousel';
import DobroLogo from '../assets/images/dobro-logo-tyrkys.png';
import KridleniLogo from '../assets/images/kridleni-logo.png';
import Tyrkysova from '../assets/images/logo_tyrkysova.jpeg';

import PartnerToptax from '../assets/images/partners/toptax-1.png';
import StoriesCarouselBlock from '../blocks/StoriesCarouselBlock';

const Homepage = () => {

  return (
    <>
        <HomepageCarousel />

        <section className="feature-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="feature-grids">
                            <div className="grid image">
                            <Link to="/clanek/dobrovolnictvi">
                                <div className="grid-img">
                                  <img src={DobroLogo} alt="Dobrovolnictví" />
                                </div>
                                </Link>
                            </div>
                            <div className="grid image">
                                <a href="https://www.kridleni.cz">
                              <div className="grid-img">
                                <img src={KridleniLogo} alt="Křídlení"/>
                                </div>
                                </a>
                            </div>
                            <div className="grid image">
                            <a href="https://tyrkysovasbirka.cz">
                              <div className="grid-img">
                                  <img src={Tyrkysova} alt="tyrkysOVA!!!"/>
                                </div>
                                </a>
                            </div>
                            <div className="grid">
                            <Link to="/dobrovouchery">
                                <i className="fi flaticon-shopping-basket"></i>
                                <h3>Nákup</h3>
                                </Link>
                            </div>
                            <div className="grid">
                            <Link to="/top-dobrovouchery">
                                <i className="fi flaticon-heart"></i>
                                <h3>TOP výběr</h3>
                                </Link>
                            </div>
                            <div className="grid">
                            <Link to="/partneri">
                                <i className="fi flaticon-donation"></i>
                                <h3>Partneři</h3>
                                </Link>
                            </div>                                                         
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="partner-section-s2">
            <div className="container">
                <div className="row">
                    <div className="col col-md-5">
                        <div className="section-title">
                            <span>#Partneři</span>
                            <h2>Pomáhají nám</h2>
                        </div>
                        <div className="partner-details">
                            <p>Díky těmto partnerům jsme schopni ....</p>
                        </div>
                    </div>
                    <div className="col col-md-7">
                        <div className="partners-area clearfix">
                            <div>
                                <img src={PartnerToptax} alt="TOPTAX" />
                            </div>                         
                        </div>
                    </div>
                </div>
            </div>
        </section>            

        <section className="causes-section causes-section-s2 section-padding">
            <div className="container-fluid">
                <div className="section-title-s2">
                    <span>#Příběhy</span>
                    <h2>DOBRO  <span>příběhy</span></h2>
                </div>
                <StoriesCarouselBlock onlyHP={true}/>
            </div>
        </section>


    </>
  );
}

export default Homepage;
